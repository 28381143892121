<template>
  <div class="d-flex flex-column flex-md-row gap-20">
    <z-card
      v-if="
        $vuetify.breakpoint.mdAndUp ||
        $route.name === 'credit-card-registration' ||
        $route.name === 'unregistered-payment'
      "
      width="100%"
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" #title>
        <div class="d-flex flex-column gap-10">
          <h3>{{ $t("warrantyRegister.warranty_registration") }}</h3>

          <p
            v-if="
              $route.name === 'credit-card-registration' ||
              $route.name === 'unregistered-payment'
            "
            class="body-text"
          >
            {{ $t("warrantyRegister.required_info") }}
          </p>
          <v-divider class="mb-3"></v-divider>
        </div>
      </template>

      <template #content>
        <div class="warranty-layout-container">
          <expenses-card
            v-if="$route.name !== 'processing-payment'"
            :check-in-date="expensesInfo.checkInDate"
            :check-out-date="expensesInfo.checkOutDate"
            :expense-items="expensesInfo.expenseItems"
            :hotel-name="expensesInfo.hotelName"
            :reservation-number="expensesInfo.reservationNumber"
          ></expenses-card>

          <z-page-transition
            v-if="$vuetify.breakpoint.mdAndUp"
            :type="$vuetify.breakpoint.smAndDown ? 'fade' : 'fade-in-right'"
          >
            <router-view />
          </z-page-transition>
        </div>
      </template>
    </z-card>

    <z-page-transition
      v-if="$vuetify.breakpoint.smAndDown"
      :type="$vuetify.breakpoint.smAndDown ? 'fade' : 'fade-in-right'"
    >
      <router-view />
    </z-page-transition>
  </div>
</template>

<script setup>
import dayjs from "dayjs"
import { computed } from "vue"
import { useRouter } from "vue-router/composables"
import { useNamespacedState } from "vuex-composition-helpers"

import i18n from "@/i18n"

import ExpensesCard from "./components/ExpensesCard.vue"

const { guest } = useNamespacedState("guest", ["guest"])
const { isQualified } = useNamespacedState("guest/auth", ["isQualified"])
const { stepsConfig } = useNamespacedState("company", ["stepsConfig"])
const { reservation } = useNamespacedState("reservation", ["reservation"])
const { hotel } = useNamespacedState("company", ["hotel"])
const { order } = useNamespacedState("warranty/payment", ["order"])

const warrantyRegistrationConfig = stepsConfig.value.find(
  (step) => step.id === "registro-de-garantia"
)

const formattedDates = computed(() => {
  return {
    checkin: dayjs(reservation.value.checkinDate.replace("Z", "")).format(
      "DD/MM/YYYY"
    ),
    checkout: dayjs(reservation.value.checkoutDate.replace("Z", "")).format(
      "DD/MM/YYYY"
    ),
  }
})

const expensesInfo = {
  hotelName: hotel.value.nomeFantasia,
  checkInDate: formattedDates.value.checkin,
  checkOutDate: formattedDates.value.checkout,
  reservationNumber: reservation.value.reservationNumber,
  expenseItems: [
    {
      name: i18n.tc("warrantyRegister.warranty_registration"),
      amount: order.value?.chargesAtCheckin[0]?.amount ?? 0,
    },
  ],
}

const router = useRouter()

if (
  guest.value.personId &&
  isQualified.value &&
  warrantyRegistrationConfig.rules.enableVirtualWallet
) {
  router.replace({ name: "wallet-payment" })
} else {
  router.replace({ name: "unregistered-payment" })
}
</script>

<style scoped lang="scss">
.warranty-layout-container {
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 50px;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
}
</style>
