var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column flex-md-row gap-20"},[(
      _vm.$vuetify.breakpoint.mdAndUp ||
      _vm.$route.name === 'credit-card-registration' ||
      _vm.$route.name === 'unregistered-payment'
    )?_c('z-card',{attrs:{"width":"100%"},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mdAndUp)?{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex flex-column gap-10"},[_c('h3',[_vm._v(_vm._s(_vm.$t("warrantyRegister.warranty_registration")))]),(
            _vm.$route.name === 'credit-card-registration' ||
            _vm.$route.name === 'unregistered-payment'
          )?_c('p',{staticClass:"body-text"},[_vm._v(" "+_vm._s(_vm.$t("warrantyRegister.required_info"))+" ")]):_vm._e(),_c('v-divider',{staticClass:"mb-3"})],1)]},proxy:true}:null,{key:"content",fn:function(){return [_c('div',{staticClass:"warranty-layout-container"},[(_vm.$route.name !== 'processing-payment')?_c(_setup.ExpensesCard,{attrs:{"check-in-date":_setup.expensesInfo.checkInDate,"check-out-date":_setup.expensesInfo.checkOutDate,"expense-items":_setup.expensesInfo.expenseItems,"hotel-name":_setup.expensesInfo.hotelName,"reservation-number":_setup.expensesInfo.reservationNumber}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('z-page-transition',{attrs:{"type":_vm.$vuetify.breakpoint.smAndDown ? 'fade' : 'fade-in-right'}},[_c('router-view')],1):_vm._e()],1)]},proxy:true}],null,true)}):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('z-page-transition',{attrs:{"type":_vm.$vuetify.breakpoint.smAndDown ? 'fade' : 'fade-in-right'}},[_c('router-view')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }